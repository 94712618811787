@import './thumbnail.css';
@import './markdown.css';
@import './print-label.css';
@import './qr-printing.css';
@import './nav-action-child.css';

:root {
  --colors-brand-500: #253c32;
  --colors-primary-500: #02c874;
  --colors-secondary-500: #61716a;
}

@tailwind base;

h1 {
  @apply text-2xl font-semibold md:text-3xl;
}

h2 {
  @apply text-xl font-semibold md:text-2xl;
}

h3 {
  @apply text-lg font-semibold md:text-xl;
}

a {
  /*@apply text-blue-600 underline;*/
}

.container {
  @apply mx-auto px-4 py-0;
}

.basic-container {
  @apply mx-auto w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl;
}

@keyframes button-ping {
  75%,
  100% {
    transform: scale(1.1, 1.3);
    opacity: 0;
  }
}

*:focus {
  outline: none !important;
}

a:focus-visible,
button:not(.outline-none):focus-visible {
  outline: 1px solid #02c874 !important;
  @apply rounded ring;
}

.animate-button-ping {
  animation: button-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

/* These styles are copied from ui/Input.tsx */

.braintree-hosted-field {
  @apply flex h-10 flex-grow items-center rounded border transition-all duration-200 overflow-hidden;
}

.braintree-hosted-fields-focused {
  @apply ring border-green-500;
}

.braintree-hosted-field.error, .braintree-hosted-fields-invalid {
  @apply border-red-500 bg-white ring-red-500 ring-opacity-30
}

.braintree-hosted-fields-valid {}


@tailwind components;
@tailwind utilities;
